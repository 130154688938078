import styled from "styled-components"

import { MDialog, TMDialogProps } from "src/ui/Dialog/MDialog"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

//TODO: WEB-429 add translations to default strings like ok and cancel.
export function ConfirmDialog(props: Omit<TMDialogProps, "hideClose">) {
  return (
    <MDialog
      TitleComponent={TitleComponent}
      hideClose
      responsive={false}
      {...props}
    />
  )
}

const TitleComponent = styled(MText).attrs(() => ({ variant: "heading2" }))`
  margin-top: ${spacing.XS2};
`

// eslint-disable-next-line import/no-default-export
export default ConfirmDialog
