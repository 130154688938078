// RESOURCES:
// https://web.dev/serial/
// https://codelabs.developers.google.com/codelabs/web-serial
// Heavily inspired by:
// https://gist.github.com/joshpensky/426d758c5779ac641d1d09f9f5894153

import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

import {
  PortState,
  SerialContext,
  SerialMessageCallback,
} from "src/components/RemoteDebugService/SerialContext"
import { Logger } from "src/utils/logger"

const debug = new Logger({ prefix: "[DEBUG SerialProvider]:" })
const logger = new Logger({ prefix: "[SerialProvider]:" })
const dataLog = new Logger({ prefix: "[data]" })

// Device vendor and product IDs for serial devices can be identified by
// plugging in the device and visiting: chrome://device-log/ . The IDs will be
// labeled `vid` and `pid`, respectively.
const DEVICE_FILTERS = [
  { usbVendorId: 0x0483, usbProductId: 0xa269 }, // P2
  { usbVendorId: 0x1fc9, usbProductId: 0x82f6 }, // Amarillo
]

interface SerialProviderProps {
  withAutoConnect?: boolean
}

const canUseSerial = "serial" in navigator

export function SerialProvider({
  children,
  withAutoConnect = false,
}: PropsWithChildren<SerialProviderProps>) {
  const [autoConnectEnabled, setAutoConnectEnabled] =
    useState(!!withAutoConnect)
  const [portState, setPortState] = useState<PortState>("closed")
  const [hasTriedAutoconnect, setHasTriedAutoconnect] = useState(false)
  const [hasManuallyDisconnected, setHasManuallyDisconnected] = useState(false)

  // XXX: See if we can expose the writer directly instead of its stream accessor
  const [writableStream, setWritableStream] =
    useState<WritableStream<Uint8Array>>()

  // XXX: See if we can reduce the number of refs
  const portRef = useRef<SerialPort | null>(null)
  const readerRef = useRef<ReadableStreamDefaultReader | null>(null)
  const readerClosedPromiseRef = useRef<Promise<void>>(Promise.resolve())
  const subscribersMapRef = useRef<Map<string, SerialMessageCallback>>(
    new Map()
  )

  /** Subscribes a callback function to the message event. */
  const subscribe = useCallback(
    (id: string, callback: SerialMessageCallback) => {
      if (subscribersMapRef.current.has(id)) {
        debug.warn("Subscriber already present, overwriting")
      }
      subscribersMapRef.current.set(id, callback)
      debug.log("Subscribers", subscribersMapRef.current)

      return id
    },
    []
  )

  const unsubscribe = useCallback((id: string) => {
    debug.log(`Unsubscribing ${id}`)
    subscribersMapRef.current.delete(id)
  }, [])

  /** Reads from the given port until it's been closed. */
  const readUntilClosed = async (port: SerialPort) => {
    if (port.readable) {
      debug.warn(">>> PORT IS READABLE")
      readerRef.current = port.readable.getReader()

      try {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          debug.log("reading data")
          const { value, done } = await readerRef.current.read()
          debug.log("data read")
          dataLog.log(value)
          if (done) {
            break
          }
          debug.log("notifying subscribers", subscribersMapRef.current)
          const timestamp = Date.now()
          Array.from(subscribersMapRef.current).forEach(([_name, callback]) => {
            callback({ value, timestamp })
          })
        }
      } catch (error) {
        dataLog.error(error)
      } finally {
        readerRef.current.releaseLock()
      }
    }
  }

  /** Event handler for when the port is disconnected. */
  const handlePortDisconnect = useCallback(async () => {
    // XXX: Look into proper disconnect handling, and try to consolidate this
    // code with the manual disconnect code.
    logger.warn("Disconnected from device! Cleaning up...")

    // Close writer
    if (writableStream) await writableStream?.getWriter().close()
    setWritableStream(undefined)

    // Wait for the reader to finish it's current loop
    await readerClosedPromiseRef.current
    try {
      if (!readerRef.current?.closed) await readerRef.current?.cancel()
    } catch (e) {
      debug.log(e)
    }
    navigator.serial.removeEventListener("disconnect", handlePortDisconnect)

    // Reset state
    readerRef.current = null
    readerClosedPromiseRef.current = Promise.resolve()
    portRef.current = null

    setHasTriedAutoconnect(false)
    setPortState("closed")
    logger.log("Cleanup complete.")
    // We do not want to depend on writableStream here
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /** Attempts to open the given port. */
  const handlePortOpen = useCallback(
    async (port: SerialPort) => {
      try {
        await port.open({ baudRate: 9600 })
        portRef.current = port
        setPortState("open")
        setHasManuallyDisconnected(false)
        logger.log("Port open!")
        setWritableStream(port.writable)

        // Attach reader to port
        readerRef.current?.cancel()
        readerClosedPromiseRef.current.then(() => {
          readerRef.current = null
          readerClosedPromiseRef.current = readUntilClosed(port)
        })

        // Attach a listener for when the device is disconnected
        navigator.serial.addEventListener("disconnect", handlePortDisconnect)
      } catch (error) {
        setPortState("closed")
        logger.error("Could not open port")
      }
    },
    [handlePortDisconnect]
  )

  const manualConnect = useCallback(async () => {
    if (!canUseSerial) return false
    if (portState === "closed") {
      setPortState("opening")

      try {
        const filters = DEVICE_FILTERS
        const port = await navigator.serial.requestPort({ filters })
        await handlePortOpen(port)
        return true
      } catch (error) {
        setPortState("closed")
        logger.error("User did not select port")
      }
    }
    return false
  }, [handlePortOpen, portState])

  const manualDisconnect = useCallback(async () => {
    debug.log("Disconnecting...")
    const port = portRef.current
    if (port) {
      setPortState("closing")

      debug.log("Cancel writing to port")
      await writableStream?.getWriter().close()
      setWritableStream(undefined)

      debug.log("Cancel reading from port")
      readerRef.current?.cancel()
      await readerClosedPromiseRef.current
      readerRef.current = null

      try {
        debug.log("Close port")
        await port.close()
      } catch (e) {
        logger.warn(e)
      }
      portRef.current = null

      // Update port state
      setHasManuallyDisconnected(true)
      setHasTriedAutoconnect(false)
      setPortState("closed")
    }
  }, [writableStream])

  // Add listener that tries to auto-connect to a device when it is plugged in
  useEffect(() => {
    if (!canUseSerial) return
    if (!autoConnectEnabled) {
      navigator.serial.removeEventListener("connect", handlePluggedIn)
      return
    }

    async function handlePluggedIn() {
      debug.log("Device plugged in! Attempting to connect...", Date())
      setPortState("opening")
      const availablePorts = await navigator.serial.getPorts()
      if (availablePorts.length) {
        const port = availablePorts[0]
        // @ts-expect-error: noUncheckedIndexedAccess
        await handlePortOpen(port)
      } else {
        setPortState("closed")
      }
      setHasTriedAutoconnect(true)
    }

    navigator.serial.addEventListener("connect", handlePluggedIn)

    // effect cleanup
    return () => {
      navigator.serial.removeEventListener("connect", handlePluggedIn)
    }
  }, [autoConnectEnabled, handlePortOpen])

  return (
    <SerialContext.Provider
      value={{
        subscribe: subscribe,
        unsubscribe: unsubscribe,
        portState,
        isConnected: portState === "open",
        connect: manualConnect,
        disconnect: manualDisconnect,
        writableStream,

        canUseSerial,
        autoConnectEnabled,
        setAutoConnectEnabled,
        hasTriedAutoconnect,
        hasManuallyDisconnected,
      }}
    >
      {children}
    </SerialContext.Provider>
  )
}
