import { Redirect, Route, RouteProps } from "react-router-dom"
import { useMatch } from "react-router-dom-v5-compat"

import { EmptyState } from "src/components/EmptyState"
import { RecentlyViewedProvider } from "src/components/RecentlyViewed/RecentlyViewedProvider"
import {
  isDev,
  isParadiseProdEnv,
  isParadiseStagingEnv,
  isProdEnv,
} from "src/constants/env"
import { paradiseUrl } from "src/constants/hrefs"
import { useAppData } from "src/context/useAppData"
import { shouldAwaitAppLogin } from "src/data/auth/mobileAppAuth"
import { useBackendFlagsBase } from "src/data/flags/useBackendFlags"
import { useOrganizationQueryId } from "src/data/organizations/hooks/useOrganizationQueryId"
import { useFetchNbrOrganizations } from "src/data/organizations/queries/organizationQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { hasAccessToParadise } from "src/data/user/logic/accessLogic"
import { useDunning } from "src/data/user/logic/dunningLogic"
import { DUNNING_STAGE } from "src/data/user/user"
import { useAppLocation } from "src/hooks/useAppLocation"
import { useFeatureAvailabilityCache } from "src/hooks/useFeatureAvailabilityCache"
import { useFlags } from "src/hooks/useFlags"
import { useLogout } from "src/hooks/useLogout"
import { getInviteCodeFromUrl } from "src/router/organizationRoutes"
import { PARADISE_PATH } from "src/router/ParadiseRoutes"
import { Routes } from "src/router/routes"
import { TextButton } from "src/ui/Button/TextButton"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { locationWithOrgId } from "src/ui/Link/internalLinkUtil"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { debug, Logger } from "src/utils/logger"

const skipLogger = new Logger({
  prefix: "PrivateRoute",
  enabled: false,
})

export function PrivateRoute({
  children,
  skipMiddleware,
  ...rest
}: RouteProps & { skipMiddleware?: TSkipRouteMiddleware }) {
  // AppReady is dependant on there being an active org, which
  // the onboarding middleware ensures
  const skipAppReady = skipMiddleware?.AppReady || skipMiddleware?.Onboarding

  // N.B: Apparently Route needs to be inner most child node, since otherwise
  // the component prop on route doesn't work
  return (
    <AuthenticateMiddleware>
      <InvitationMiddleware skip={skipMiddleware?.Invitation}>
        <AppDataLoadedMiddleware skip={skipMiddleware?.AppDataLoaded}>
          <OnboardingMiddleware skip={skipMiddleware?.Onboarding}>
            <AppReadyMiddleware skip={skipAppReady}>
              <InjectOrgIdMiddleware skip={skipMiddleware?.InjectOrgId}>
                <DunningWrapperMiddleware skip={skipMiddleware?.Dunning}>
                  <RecentlyViewedProvider>
                    <Route {...rest}>{children}</Route>
                  </RecentlyViewedProvider>
                </DunningWrapperMiddleware>
              </InjectOrgIdMiddleware>
            </AppReadyMiddleware>
          </OnboardingMiddleware>
        </AppDataLoadedMiddleware>
      </InvitationMiddleware>
    </AuthenticateMiddleware>
  )
}

/**
 * Injects the currently active organization id into the url to make it possible
 * to easily copy links from the url and send them to team members when you are
 * part of multiple organizations
 */
function InjectOrgIdMiddleware({ children, skip }: IRouteMiddleware) {
  const { activeOrg } = useAppData()
  const { orgIdParam } = useOrganizationQueryId()
  const location = useAppLocation()

  if (!!skip) {
    skipLogger.log(`Skipping InjectOrgIdQueryParam due to: ${skip}`)
    return <>{children}</>
  }

  if (!orgIdParam && !!activeOrg) {
    debug.warn(
      `Orgslug missing, adding id for`,
      `${activeOrg.name}:${activeOrg.id}`
    )

    // Here we're injecting active org id as a query param:
    const newLocation = locationWithOrgId({ location, orgId: activeOrg.id })
    return <Redirect to={newLocation} />
  }

  if (!orgIdParam && !activeOrg) {
    debug.error(`Orgslug missing, no active org, so cannot add it`)
  }
  return <>{children}</>
}

export function UnauthenticatedRoute({
  children,
  fallbackPath = Routes.Dashboard.location().pathname,
  ...rest
}: RouteProps & {
  skipMiddleware?: TSkipRouteMiddleware
  fallbackPath?: string
}) {
  const { authState: signInState } = useAppData()
  const location = useAppLocation()

  if (!signInState.authorization) {
    return <Route {...rest}>{children}</Route>
  }

  return <Redirect to={{ ...location, pathname: fallbackPath }} />
}

/**
 * Waits for the user to become authenticated. If there is no authentication
 * data stored, the user is redirected to the sign in page with the
 * current location stored so that they are redirected back after logging in.
 */
function AuthenticateMiddleware({ children }: IRouteMiddleware) {
  const { authState, user } = useAppData()
  const currentLocation = useAppLocation()
  const { logoutLocation } = useLogout()

  if (shouldAwaitAppLogin(!!authState.authorization)) {
    return <LoadingScreen debugInfo="Awaiting external login" />
  }

  if (!authState.authorization) {
    return (
      <Redirect
        to={logoutLocation({
          fromLocation: currentLocation,
          reason: "not authorized, redirecting to logout/login",
        })}
      />
    )
  }

  if (!user) {
    return <LoadingScreen debugInfo={"Fetching user data"} />
  }

  return <>{children}</>
}

/**
 * Waits for all essential app data to load.
 */
function AppDataLoadedMiddleware({ children, skip }: IRouteMiddleware) {
  const { criticalError, loading } = useAppData()
  const { logoutLocation } = useLogout()
  const currentLocation = useAppLocation()

  if (!!skip) {
    skipLogger.log(`Skipping AppDataLoadedMiddlware due to: ${skip}`)
    return <>{children}</>
  }

  if (loading) {
    return <LoadingScreen debugInfo={"Fetching app data"} />
  }

  if (criticalError) {
    debug.error(criticalError)
    return (
      <Redirect
        to={logoutLocation({
          reason: "There was an error with AppData",
          fromLocation: currentLocation,
        })}
      />
    )
  }

  return <>{children}</>
}

/**
 * Check if the user needs to be onboarded. If so user is redirected to the
 * onboarding flow.
 * To prevent race conditions 'skip' is used. Otherwice race condition from invalidating cache could occur
 * which would unmount component before they have finished.
 */
function OnboardingMiddleware({ children, skip }: IRouteMiddleware) {
  const user = useGetUser()
  const location = useAppLocation()
  const userId = user.user_id

  const fetchNbrOrganizations = useFetchNbrOrganizations({ userId })
  const orgCount = fetchNbrOrganizations.data ?? {
    total: 0,
    owned: 0,
  }

  const isOrgCreateRoute = useMatch(Routes.OrgCreate.location().pathname)

  if (fetchNbrOrganizations.isInitialLoading) {
    return <LoadingScreen debugInfo={`Checking available organizations`} />
  }

  if (!!skip) {
    skipLogger.log(`Skipping OnboardingMiddleware due to:  ${skip}`)
    return <>{children}</>
  }

  if (orgCount.total < 1) {
    debug.log("User has no organization => Redirecting to onboarding flow")
    return <Redirect to={{ ...location, ...Routes.Onboarding.location() }} />
  }

  if (isOrgCreateRoute && orgCount.owned === 0) {
    debug.log(
      "User does not own any organization => Redirecting to onboarding create organization flow"
    )
    return (
      <Redirect
        to={{ ...location, ...Routes.OnboardingCreateOrganization.location() }}
      />
    )
  }

  return <>{children}</>
}

/**
 * Checks if we have all the information we need to render the app. E.g.
 * feature availability data, so that the UI doesn't flicker and jump around
 * after it has finished fetching.
 * This middleware assumes that the user has gotten an org (so must be run
 * after the onboarding middleware).
 */
function AppReadyMiddleware({ children, skip }: IRouteMiddleware) {
  const { ready, user, activeOrg } = useAppData()
  const { loading: featuresLoading } = useFeatureAvailabilityCache()
  const { fetchFlags } = useBackendFlagsBase({
    orgId: activeOrg?.id ?? "",
    options: {
      enabled: !!activeOrg?.id,
    },
  })
  const { logout } = useLogout()
  const isInParadisePath = useMatch(PARADISE_PATH + "/*")

  const isParadiseSubdomain = isParadiseProdEnv || isParadiseStagingEnv

  if (!!skip) {
    skipLogger.log(`Skipping AppReadyMiddleware due to: ${skip}`)
    return <>{children}</>
  }

  if (featuresLoading || fetchFlags.isLoading) {
    return <LoadingScreen debugInfo={`Loading features`} />
  }

  if (!ready) {
    return <LoadingScreen debugInfo={`Waiting for app ready`} />
  }

  // If in paradise2.* and trying to go into webapp (not /paradise), then go back to /paradise
  if (
    isParadiseSubdomain &&
    !isInParadisePath &&
    hasAccessToParadise(user?.roles ?? [])
  ) {
    return <Redirect to={Routes.ParadiseDashboard.location()} />
  }

  // If in web.* and trying to login as superadmin, then redirect to paradise2.*
  if (
    !isParadiseSubdomain &&
    !isDev &&
    hasAccessToParadise(user?.roles ?? [])
  ) {
    return (
      <EmptyState title="You are signed in as a super user" icon={null}>
        <div>You can only view Paradise with this account</div>
        <div>Current acccount: {user?.email}</div>
        <div>
          <TextButton
            onClick={() =>
              logout({ reason: "User clicked logout in access denied" })
            }
          >
            Sign out
          </TextButton>{" "}
          |{" "}
          <ExternalLink
            href={isProdEnv ? paradiseUrl.production : paradiseUrl.staging}
          >
            Go to Paradise
          </ExternalLink>
        </div>
      </EmptyState>
    )
  }

  if (isParadiseSubdomain && !hasAccessToParadise(user?.roles ?? [])) {
    return (
      <EmptyState title="You do not have access to this" icon={null}>
        {user?.email} |{" "}
        <TextButton
          onClick={() =>
            logout({ reason: "User clicked logout in access denied" })
          }
        >
          Sign out
        </TextButton>
      </EmptyState>
    )
  }

  return <>{children}</>
}

// Check if url contains invite code, if so redirect user to invite flow.
function InvitationMiddleware({ children, skip }: IRouteMiddleware) {
  const inviteCode = getInviteCodeFromUrl()
  const location = useAppLocation()

  if (!!skip) {
    skipLogger.log(`Skipping InvitationMiddleware due to: ${skip}`)
    return <>{children}</>
  }

  if (inviteCode) {
    debug.log("Invite code found => Redirecting to onboarding flow")
    return <Redirect to={{ ...location, ...Routes.Onboarding.location() }} />
  }

  return <>{children}</>
}

// Wrapper added to skip the middleware entirely in order not to call
// useDunning - which depends on an organization being present, which is
// not the case for some routes.
function DunningWrapperMiddleware({ children, skip }: IRouteMiddleware) {
  if (!!skip) {
    skipLogger.log(`Skipping DunningMiddleware due to ${skip}`)
    return <>{children}</>
  }

  return <DunningMiddleware children={children} />
}

function DunningMiddleware({ children }: IRouteMiddleware) {
  const dunningData = useDunning()
  const location = useAppLocation()
  const user = useGetUser()
  const { homeDeleteReactivation } = useFlags()

  const cancelledWhitelist = [
    Routes.Dashboard.location().pathname,
    Routes.AccountGeneral.location().pathname,
    Routes.AccountBilling.location().pathname,
    Routes.ChangePlan.location().pathname,
    Routes.ChangePlanSuccess.location().pathname,
    Routes.Organizations.location().pathname,
    ...(dunningData.isOwner && homeDeleteReactivation
      ? [Routes.Homes.location().pathname]
      : []),
  ]
  const suspendedWhitelist = [
    ...cancelledWhitelist,
    Routes.Organization.location().pathname,
  ]

  // No need to check superusers to prevent infinite redirections
  if (hasAccessToParadise(user.roles)) {
    return <>{children}</>
  }

  if (
    (dunningData.stage === DUNNING_STAGE.DUNNING_SUSPENDED &&
      !suspendedWhitelist.includes(location.pathname)) ||
    (dunningData.stage === DUNNING_STAGE.DUNNING_CANCELLED &&
      !cancelledWhitelist.includes(location.pathname))
  ) {
    debug.log(
      `User is not allowed to access route due to dunning stage = ${dunningData.stage}, redirecting to dashboard.`
    )
    return <Redirect to={{ ...location, ...Routes.Dashboard.location() }} />
  }

  return <>{children}</>
}

interface TSkipRouteMiddleware {
  Onboarding?: string
  Invitation?: string
  InjectOrgId?: string
  AppDataLoaded?: string
  AppReady?: string
  Dunning?: string
}

interface IRouteMiddleware {
  children: React.ReactNode
  skip?: string
}
