import { useRef, useState } from "react"
import styled from "styled-components"

import {
  arrow as arrowMiddleware,
  autoUpdate,
  flip as flipMiddleware,
  FloatingArrow,
  FloatingPortal,
  offset as offsetMiddleware,
  safePolygon,
  shift,
  useDismiss,
  useFocus,
  useHover,
  useInteractions,
} from "@floating-ui/react"
import { Placement, useFloating } from "@floating-ui/react"

import { Z_INDEX_ABOVE_MODAL } from "src/constants/zindex"
import { mColors } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const ARROW_HEIGHT = 7
const GAP = 5

type MTooltipProps = {
  title: React.ReactNode
  /**
   * Use this if the caller should control the open state instead of letting `MTooltip` handle it internally
   */
  open?: boolean
  enabled?: boolean
  children: React.ReactNode
  arrow?: boolean
  placement?: Placement
  /**
   * Root defines the element the popover will be positioned relative to
   * @default body
   */
  root?: HTMLElement | null | React.MutableRefObject<HTMLElement | null>
  offset?: number
  flip?: boolean
  disableFocusListener?: boolean
  interactive?: boolean
  /**
   * This defines the element the popover should anchor to,
   * most of the time this is the trigger element
   *
   * @default children
   */
  anchorEl?: HTMLElement | null
}

export function MTooltip({
  title,
  open,
  enabled = true,
  arrow = true,
  placement,
  children,
  root = undefined,
  offset,
  flip = true,
  disableFocusListener,
  interactive = true,
  anchorEl,
}: MTooltipProps) {
  const [internalOpen, setInternalOpen] = useState(false)
  const arrowRef = useRef(null)

  const _open = open === undefined ? internalOpen : open

  const floating = useFloating<HTMLDivElement>({
    open: _open,
    elements: {
      reference: anchorEl,
    },
    onOpenChange: (open) => {
      setInternalOpen(open)
    },
    middleware: [
      offsetMiddleware(offset ?? ARROW_HEIGHT + GAP),
      flip && flipMiddleware(),
      shift(),
      arrowMiddleware({
        element: arrowRef,
        padding: 10,
      }),
    ],
    placement,
    transform: true,
    whileElementsMounted: autoUpdate,
  })

  const dismiss = useDismiss(floating.context)

  const hover = useHover(floating.context, {
    delay: { open: 100 },
    enabled: open === undefined,
    move: false,
    handleClose: interactive ? safePolygon() : null,
  })
  const focus = useFocus(floating.context, {
    enabled: !disableFocusListener,
  })

  const interaction = useInteractions([hover, focus, dismiss])

  return (
    <>
      <ReferenceWrapper
        {...interaction.getReferenceProps()}
        ref={floating.refs.setReference}
        tabIndex={disableFocusListener ? -1 : 0}
      >
        {children}
      </ReferenceWrapper>
      {_open && title && enabled && (
        <FloatingPortal root={root}>
          <FloatingWrapper
            {...interaction.getFloatingProps()}
            style={floating.floatingStyles}
            ref={floating.refs.setFloating}
          >
            {arrow && (
              <FloatingArrow
                ref={arrowRef}
                context={floating.context}
                height={ARROW_HEIGHT}
                fill={mColors.primaryLight}
                tipRadius={2}
              />
            )}
            <MText variant="bodyS">{title}</MText>
          </FloatingWrapper>
        </FloatingPortal>
      )}
    </>
  )
}

const ReferenceWrapper = styled.span``

const FloatingWrapper = styled.div`
  padding: ${spacing.S};
  background-color: ${mColors.primaryLight};
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 1rem;
  max-width: 300px;

  z-index: ${Z_INDEX_ABOVE_MODAL};
`
