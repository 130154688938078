import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { homeGroupKeys } from "src/data/homeGroups/queries/homeGroupQueryCache"
import {
  IFetchHomeGroups,
  IHomeGroupFilter,
  IPostHomeGroup,
  THomeGroup,
  TPatchHomeGroup,
} from "src/data/homeGroups/types/homeGroupTypes"
import { ErrorService } from "src/utils/ErrorService"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"
import { enabledChecker } from "src/utils/reactQueryUtil"

export function useFetchHomeGroups({
  orgId,
  filter,
  options,
}: {
  orgId: string
  filter: IHomeGroupFilter
  options?: UseQueryOptions<
    IFetchHomeGroups,
    AxiosError,
    IFetchHomeGroups,
    ReturnType<typeof homeGroupKeys.homeGroupsList>
  >
}) {
  // TODO PRD-421: Implement fetchHomeGroups once endpoint is available
  async function fetchHomeGroups(): Promise<IFetchHomeGroups> {
    const response = await minutApiHttpClient.get<IFetchHomeGroups>(
      `${API_DEFAULT}/organizations/${orgId}/homegroups`,
      {
        params: filter,
      }
    )

    return response.data
  }

  return useQuery(
    homeGroupKeys.homeGroupsList(orgId, filter),
    fetchHomeGroups,
    {
      ...options,
    }
  )
}

export function useFetchHomeGroup({
  orgId,
  homeGroupId,
  options,
}: {
  orgId: string
  homeGroupId: string
  options?: UseQueryOptions<
    THomeGroup,
    AxiosError,
    THomeGroup,
    ReturnType<typeof homeGroupKeys.homeGroup>
  >
}) {
  async function fetchHomeGroup(): Promise<THomeGroup> {
    const response = await minutApiHttpClient.get<THomeGroup>(
      `${API_DEFAULT}/organizations/${orgId}/homegroups/${homeGroupId}`
    )

    return response.data
  }

  return useQuery(homeGroupKeys.homeGroup(orgId, homeGroupId), fetchHomeGroup, {
    ...options,
    enabled: enabledChecker(options, homeGroupId),
  })
}

interface IPostHomeGroupMutation {
  orgId: string
  body: IPostHomeGroup
}

export function usePostHomeGroup() {
  const queryClient = useQueryClient()

  async function postHomeGroup({
    orgId,
    body,
  }: IPostHomeGroupMutation): Promise<THomeGroup> {
    const response = await minutApiHttpClient.post<THomeGroup>(
      `${API_DEFAULT}/organizations/${orgId}/homegroups`,
      body
    )

    return response.data
  }

  return useMutation<THomeGroup, AxiosError, IPostHomeGroupMutation>(
    postHomeGroup,
    {
      onSuccess: (vars) => {
        return queryClient.invalidateQueries(
          homeGroupKeys.homeGroups(vars.organization_id)
        )
      },
      onError: (error) => {
        ErrorService.captureException(error)
      },
    }
  )
}

interface IPatchHomeGroupMutation {
  orgId: string
  homeGroupId: string
  body: TPatchHomeGroup
}
export function usePatchHomeGroup() {
  const queryClient = useQueryClient()

  async function patchHomeGroup({
    orgId,
    homeGroupId,
    body,
  }: IPatchHomeGroupMutation): Promise<THomeGroup> {
    const response = await minutApiHttpClient.patch<THomeGroup>(
      `${API_DEFAULT}/organizations/${orgId}/homegroups/${homeGroupId}`,
      body
    )

    return response.data
  }

  return useMutation<THomeGroup, AxiosError, IPatchHomeGroupMutation>(
    patchHomeGroup,
    {
      onSuccess: (_, { orgId }) => {
        return queryClient.invalidateQueries(homeGroupKeys.homeGroups(orgId))
      },
    }
  )
}

interface IDeleteHomeGroupMutation {
  orgId: string
  homeGroupId: string
}
export function useDeleteHomeGroup() {
  const cache = useQueryClient()

  async function deleteHomeGroup({
    orgId,
    homeGroupId,
  }: IDeleteHomeGroupMutation): Promise<void> {
    const response = await minutApiHttpClient.delete(
      `${API_DEFAULT}/organizations/${orgId}/homegroups/${homeGroupId}`
    )
    return response.data
  }

  return useMutation<
    void,
    AxiosError<{ error_key: "homegroup_not_empty" }>,
    IDeleteHomeGroupMutation
  >(deleteHomeGroup, {
    onSuccess(_, vars) {
      cache.invalidateQueries(homeGroupKeys.homeGroups(vars.orgId))
    },
  })
}
