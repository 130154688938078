import { CircularProgress } from "@material-ui/core"

import {
  IVirtualEnvironmentRequest,
  usePostVirtualDeviceEvent,
} from "src/data/homes/queries/virtualDeviceQueries"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"

export function EventButton({
  eventType,
  orgId,
  deviceId,
  homeId,
  label,
  disabled,
}: {
  eventType: IVirtualEnvironmentRequest["event_type"]
  orgId: string
  deviceId: string
  homeId: string
  label: string
  disabled?: boolean
}) {
  const postVirtualDeviceEvent = usePostVirtualDeviceEvent()

  return (
    <MButtonLegacy
      size="small"
      onClick={() => {
        postVirtualDeviceEvent.mutate({
          orgId,
          deviceId,
          homeId,
          eventType,
        })
      }}
      variant="outlined"
      disabled={disabled}
    >
      {postVirtualDeviceEvent.isLoading ? (
        <CircularProgress size={"22.75px"} />
      ) : (
        <span>{label}</span>
      )}
    </MButtonLegacy>
  )
}
