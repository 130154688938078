import { TSubscriptionCurrencyCode } from "src/data/billing/types/billingTypes"
import { TPostSubscriptionEstimateUpdateResponse } from "src/data/subscription/types/subscriptionTypes"

export interface ICustomerBalance {
  promotional_credits: number
  excess_payments: number
  refundable_credits: number
  unbilled_charges: number
  currency_code: TSubscriptionCurrencyCode
  balance_currency_code: TSubscriptionCurrencyCode
}

export enum Taxability {
  TAXABLE = "taxable",
  EXEMPT = "exempt",
}
export interface ICustomer {
  id: string
  first_name?: string // max chars=150
  last_name?: string // max chars=150
  email?: string
  phone?: string // max chars=50
  vat_number?: string // max chars=20
  vat_number_status?: VatNumberStatus
  billing_address?: IBillingAddress
  primary_payment_source_id?: string
  balances?: ICustomerBalance[]
  taxability?: Taxability
  preferred_currency_code?: string // max chars=3
  is_location_valid?: boolean
  cf_csm?: string
}

export interface IBillingAddress {
  first_name?: string // max chars=150
  last_name?: string // max chars=150
  email?: string // max chars=70
  company?: string // max chars=250
  phone?: string // max chars=50
  city?: string // max chars=50
  country?: string // max chars=50
  line1?: string // max chars=150
  line2?: string // max chars=150
  state?: string // max chars=50
  state_code?: string // max chars=50
  validation_status?: ValidationStatus
  zip?: string // max chars=20
}

export enum VatNumberStatus {
  VALID = "valid",
  INVALID = "invalid",
  NOT_VALIDATED = "not_validated",
  UNDETERMINED = "undetermined",
}

export enum ValidationStatus {
  NOT_VALIDATED = "not_validated",
  VALID = "valid",
  PARTIALLY_VALID = "partially_valid",
  INVALID = "invalid",
}

export interface ICard {
  first_name?: string
  last_name?: string
  brand: CardBrand
  expiry_month: number
  expiry_year: number
  last4: string
  billing_zip?: string
  masked_number?: string
}

export enum PaymentSourceType {
  CARD = "card",
  APPLE_PAY = "apple_pay",
  GOOGLE_PAY = "google_pay",
}

export enum PaymentSourceStatus {
  VALID = "valid",
  EXPIRING = "expiring",
  EXPIRED = "expired",
  INVALID = "invalid",
  PENDING_VERIFICATION = "pending_verification",
}

export interface IPaymentSource {
  id: string
  status: PaymentSourceStatus
  type: PaymentSourceType
  card?: ICard
}

export enum CardBrand {
  VISA = "visa",
  MASTERCARD = "mastercard",
  AMERICAN_EXPRESS = "american_express",
}

export enum SubscriptionStatus {
  FUTURE = "future", // The Subscription is scheduled to start in a future date.
  IN_TRIAL = "in_trial", // The subscription is in trial.
  ACTIVE = "active", // The subscription is in active state and will be charged at start of each term based on the recurring items(plan & addons etc.,).
  NON_RENEWING = "non_renewing", // The subscription will be cancelled at end of the current term.
  PAUSED = "paused", // The subscription is paused. No new recurring actions will be allowed, but any pending payments will be collected.
  CANCELLED = "cancelled", // The subscription has been cancelled. No new recurring actions will take place, but any pending payments will be collected.
}

/** Timestamp UTC in seconds */
export type UTCSeconds = number

export enum BillingPeriodUnit {
  MONTH = "month",
  YEAR = "year",
}

export type Coupon = {
  coupon_id: string
  apply_till?: UTCSeconds
  applied_count: number
  coupon_code?: string
}

export interface ISubscription {
  id: string
  customer_id: string
  plan_id: string
  payment_source_id?: string
  currency_code: TSubscriptionCurrencyCode
  plan_quantity: number
  plan_unit_price?: number // in cents
  plan_amount?: number // in cents
  billing_period?: number // nbr of $billing_period_unit
  billing_period_unit?: BillingPeriodUnit
  status: SubscriptionStatus
  start_date?: UTCSeconds
  trial_end?: UTCSeconds // End of the trial period for the subscription. Presence of this value for 'future' subscription implies the subscription will go into 'in_trial' state when it starts.
  current_term_start?: UTCSeconds
  current_term_end?: UTCSeconds
  next_billing_at?: UTCSeconds
  created_at?: UTCSeconds
  started_at?: UTCSeconds
  activated_at?: UTCSeconds
  pause_date?: UTCSeconds
  resume_date?: UTCSeconds
  cancelled_at?: UTCSeconds
  updated_at?: UTCSeconds
  due_invoice_count?: number
  total_dues?: string
  deleted: boolean
  coupons?: Coupon[]
  addons?: IChargebeeAddon[]
  has_scheduled_changes?: boolean // If true, there are subscription changes scheduled on next renewal. default=false
}

export interface IChargebeeAddon {
  id: string
  quantity?: number
  unit_price?: number //in cents
  amount?: number //in cents
  trial_end?: number //timestap UTC in seconds
  remaining_billing_cycles?: number
  quantity_in_decimal?: string
  unit_price_in_decimal?: string
  amount_in_decimal?: string
}

export interface ISubscriptionEstimate {
  object: "subscription_estimate"
  status: SubscriptionStatus
  next_billing_at: number
  currency_code: TSubscriptionCurrencyCode
}

export enum TaxExemptReason {
  TAX_NOT_CONFIGURED = "tax_not_configured", // If tax is not enabled for the site.
  REGION_NON_TAXABLE = "region_non_taxable", // If the product sold is not taxable in this region, but it is taxable in other regions, hence this region is not part of the Taxable jurisdiction.
  EXPORT = "export", // If the Merchant is not registered for Tax in this region, taxes will not be applied.
  CUSTOMER_EXEMPT = "customer_exempt", // If the Customer is marked as Tax exempt.
  PRODUCT_EXEMPT = "product_exempt", // If the Plan or Addon is marked as Tax exempt.
  ZERO_RATED = "zero_rated", // If the rate of tax is 0% and no Sales/ GST tax is collectable for that line item.
  REVERSE_CHARGE = "reverse_charge", // If the Customer is identified as B2B customer (when VAT Number is entered), applicable for EU only.
  HIGH_VALUE_PHYSICAL_GOODS = "high_value_physical_goods", // If physical goods are sold from outside Australia to customers in Australia, and the price of all the physical good line items is greater than AUD 1000, then tax will not be applied.
}

export interface ILineItem {
  //  The list of items in this estimate. optional, list of line_item
  object: "line_item"
  id?: string
  date_from: number // Start date of this lineitem. timestamp(UTC) in seconds
  date_to: number // End date of this lineitem. timestamp(UTC) in seconds
  unit_amount: number // Unit amount of the lineitem. in cents
  quantity: number // Quantity of the recurring item which is represented by this lineitem. optional, integer, default=1
  amount: number
  pricing_model?: LineItemPricingModel
  is_taxed: boolean // Specifies whether this line item is taxed or not. boolean, default=false
  tax_amount?: number // The tax amount charged for this item. optional, in cents, default=0, min=0
  tax_rate?: number // Rate of tax used to calculate tax for this lineitem. optional, double, min=0.0, max=100.0
  customer_id?: string // A unique identifier for the customer this lineitem belongs to. optional, string, max chars=100
  description: string // Detailed description about this lineitem. string, max chars=250
  entity_type: LineItemEntityType
  entity_id?: string // The identifier of the modelled entity this lineitem is based on. Will be null for 'adhoc' entity type. optional, string, max chars=100
  discount_amount?: number // Total discounts for this line. optional, in cents, min=0
  item_level_discount_amount?: number // 'Item' level discounts for this line. optional, in cents, min=0
  tax_exempt_reason?: TaxExemptReason
}

/** https://apidocs.chargebee.com/docs/api/estimates#subscription_estimate_attributes
 * @deprecated use TInvoiceEstimate instead
 */
export interface IInvoiceEstimate {
  object: "invoice_estimate"
  recurring: boolean
  price_type: InvoicePriceType
  sub_total: number // Invoice sub-total in cents. in cents, min=0
  total?: number // Invoice total in cents | optional, in cents, default=0, min=0
  credits_applied?: number // optional, in cents, default=0, min=0
  amount_paid?: number // Existing outstanding payments if any, applied to this invoice in cents. optional, in cents, default=0, min=0
  amount_due?: number // Invoice amount due in cents. optional, in cents, default=0, min=0
  line_items?: ILineItem[]
  taxes?: {
    object: "tax"
    name: string // The name of the tax applied. E.g. GST. string, max chars=100
    description?: string // Description of the tax item. optional, string, max chars=250
    amount: number // The tax amount. in cents, min=0
  }[]
  line_item_taxes: {
    object: "line_item_tax"
    tax_name: string // The name of the tax applied. string, max chars=100
    tax_rate: number // The rate of tax used to calculate tax amount. double, default=0.0, min=0.0, max=100.0
    tax_juris_type?: LineItemTaxJurisType // The type of tax jurisdiction. optional, enumerated string
    tax_juris_name?: string // The name of the tax jurisdiction. optional, string, max chars=250
    tax_juris_code?: string // The tax jurisdiction code. optional, string, max chars=250
    line_item_id?: string // The unique reference id of the line item for which the tax is applicable. optional, string, max chars=40
    tax_amount: number // The tax amount. in cents, min=0
    is_partial_tax_applied?: boolean // Indicates if tax is applied only on a portion of the line item amount. optional, boolean
    taxable_amount: number // Indicates the actual portion of the line item amount that is taxable. in cents, min=0
    is_non_compliance_tax: boolean // Indicates the non-compliance tax that should not be reported to the jurisdiction. optional, boolean
  }[]
  currency_code: TSubscriptionCurrencyCode // The currency code (ISO 4217 format) of the invoice. string, max chars=3
  round_off_amount?: number // Indicates the rounded-off amount. optional, in cents, min=0
  discounts?: IDiscount[] // The list of discounts applied to this estimate. optional, list of discount
  line_item_discounts?: {
    line_item_id: string // The unique reference id of the line item for which the discount is applicable. string, max chars=50
    discount_type: DiscountItemType // Type of this discount line item. enumerated string
    coupon_id?: string // Coupon id. optional, string, max chars=50
    discount_amount: number // Discount amount. in cents, min=0
  }[] // The list of discount(s) applied for each line item of this invoice. optional, list of line_item_discount
}

export interface IDiscount {
  amount: number // Discount amount. in cents, min=0
  description?: string // Detailed description of this discount line item. optional, string, max chars=250
  entity_type: DiscountItemType // Type of this Discount lineitem. enumerated string
  entity_id?: string // The identifier of the modelled entity this lineitem is bases on. Will be null for 'Prorated Credits' & 'Promotional Credits'. optional, string, max chars=100
}

export enum DiscountItemType {
  ITEM_LEVEL_COUPON = "item_level_coupon", // Represents the 'Item' level coupons applied to this invoice. Further the 'coupon_id' attribute specifies the coupon id this discount is based on.
  DOCUMENT_LEVEL_COUPON = "document_level_coupon", // Represents the 'Document' level coupons applied to this document. Further the 'coupon_id' attribute specifies the coupon id this discount is based on.
  PROMOTIONAL_CREDITS = "promotional_credits", // Represents the Promotional Credits item in invoice. The 'coupon_id' attribute will be null in this case.
  PRORATED_CREDITS = "prorated_credits", // Represents the credit adjustment items in invoice. The 'coupon_id' attribute will be null in this case.
}

enum LineItemTaxJurisType {
  COUNTRY = "country", // The tax jurisdiction is a country.
  FEDERAL = "federal", // The tax jurisdiction is a federal.
  STATE = "state", // The tax jurisdiction is a state.
  COUNTY = "county", // The tax jurisdiction is a county.
  CITY = "city", // The tax jurisdiction is a city.
  SPECIAL = "special", // Special tax jurisdiction.
  UNINCORPORATED = "unincorporated", // Combined tax of state and county.
  OTHER = "other", // Jurisdictions other than the ones listed above.
}

export enum InvoicePriceType {
  TAX_EXCLUSIVE = "tax_exclusive",
  TAX_INCLUSIVE = "tax_inclusive",
}

/** Charges a single price for the plan/addon. optional, enumerated string */
export enum LineItemPricingModel {
  FLAT_FEE = "flat_fee", // Charge a single price on recurring basis.
  PER_UNIT = "per_unit", // Charge the price for each unit of the plan for the subscription on recurring basis.
  STAIRSTEP = "stairstep", // Charges a price for a range.
  TIERED = "tiered", // Charges a different per unit price for the quantity purchased from every tier.
  VOLUME = "volume", // Charges the per unit price for the total quantity purchased based on the tier under which it falls.
}

export enum LineItemEntityType {
  PLAN_SETUP = "plan_setup", // Indicates that this lineitem is based on 'Plan Setup' charge. The 'entity_id' attribute specifies the plan id.
  PLAN = "plan", // Indicates that this lineitem is based on 'Plan' entity. The 'entity_id' attribute specifies the plan id.
  ADDON = "addon", // Indicates that this lineitem is based on 'Addon' entity. The 'entity_id' attribute specifies the addon id.
  ADHOC = "adhoc", // Indicates that this lineitem is not modelled. i.e created adhoc. So the 'entity_id' attribute will be null in this case.
}

/** @deprecated Use TEstimate instead */
export interface IEstimate {
  object: "estimate"
  subscription_estimate?: ISubscriptionEstimate
  invoice_estimate?: IInvoiceEstimate
  next_invoice_estimate?: IInvoiceEstimate
  credit_note_estimates?: ICreditNoteEstimate[]
}

export interface ICreditNoteEstimate {
  total: number // Credit note total in cents. min=0
  amount_allocated: number // Allocated credits in cents.
  amount_available: number // Remaining credits in cents.
  currency_code: string // The currency code (ISO 4217 format) of the credit note. max chars = 3
}

export enum InvoiceStatusType {
  PAID = "paid", // Indicates a paid invoice.
  POSTED = "posted", // Indicates the payment is not yet collected and will be in this state till the due date to indicate the due period.
  PAYMENT_DUE = "payment_due", // Indicates the payment is not yet collected and is being retried as per retry settings.
  NOT_PAID = "not_paid", // Indicates the payment is not made and all attempts to collect is failed.
  VOIDED = "voided", // Indicates a voided invoice.
  PENDING = "pending", //Indicates the invoice is not closed yet. New line items can be added when the invoice is in this state.
}

export enum DunningStatusType {
  IN_PROGRESS = "in_progress", // Dunning is still in progress.
  EXHAUSTED = "exhausted", // Maximum number of attempts have been made.
  STOPPED = "stopped", // Dunning has stopped for this invoice.
  SUCCESS = "success", // Payment successfully collected during dunning process.
}

export interface IInvoice {
  id: string
  po_number?: string // Purchase Order Number for this invoice.
  customer_id: string
  price_type: InvoicePriceType
  date?: UTCSeconds // Date indicated on the invoice. The date is based on your billing configuration - normal billing or metered billing.
  due_date?: UTCSeconds
  currency_code: TSubscriptionCurrencyCode // The currency code (ISO 4217 format) for the invoice.
  total?: number // Invoiced amount in cents.
  amount_adjusted?: number // Total adjustments made against this invoice in cents.
  write_off_amount?: 0 // Amount written off against this invoice in cents.
  amount_due?: number
  amount_paid?: number // Total payments received for this invoice in cents.
  recurring?: boolean // Boolean indicating whether this invoice belongs to a subscription.
  amount_to_collect?: 0
  billing_address?: IBillingAddress
  credits_applied?: number // Total credits applied against this invoice in cents.
  deleted: boolean
  object: "invoice"
  paid_at?: UTCSeconds // Timestamp indicating the date & time this invoice got paid.
  round_off_amount?: 0
  status: InvoiceStatusType // Current status of this invoice.
  sub_total: number
  tax: 0
  dunning_status?: DunningStatusType
  next_retry_at?: UTCSeconds
}

export type TEstimate = NonNullable<
  TPostSubscriptionEstimateUpdateResponse["estimate"]
>
export type TEstimateBreakdown = NonNullable<
  TPostSubscriptionEstimateUpdateResponse["breakdown"]
>
export type TInvoiceEstimate = NonNullable<TEstimate["invoice_estimate"]>
export type TDiscount = NonNullable<TInvoiceEstimate["discounts"]>[number]
export type TCreditNoteEstimate = NonNullable<
  TEstimate["credit_note_estimates"]
>[number]
export type TInvoiceEstimateTax = NonNullable<TInvoiceEstimate["taxes"]>[number]
