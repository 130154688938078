import { EmptyStateWithImage } from "src/components/EmptyState/EmptyStateWithImage"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MButton } from "src/ui/Button/MButton"
import { spacing } from "src/ui/spacing"

import HomeGroupsEmptyState from "./images/locations-empty-state.svg"

export function NoHomeGroups() {
  const { t, langKeys } = useTranslate()

  return (
    <EmptyStateWithImage
      Image={HomeGroupsEmptyState}
      title={t(langKeys.locations_empty_state_title)}
      description={t(langKeys.locations_empty_state_description)}
      imageSpacing={`-${spacing.XL}`}
      content={
        <MButton appHref={Routes.CreateHomeGroup.location()}>
          {t(langKeys.organizations_home_group_create_button_label)}
        </MButton>
      }
    />
  )
}
