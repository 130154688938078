import { SvgIconProps } from "@material-ui/core"

import { TIndoorClimateMonitoring } from "src/data/homes/types/homeTypes"
import { colorScale, mColors } from "src/ui/colors"
import IndoorClimateFilledIcon from "src/ui/icons/indoor-climate-filled.svg"

export function IndoorClimateIcon({
  state,
  status,
  iconProps,
}: {
  state: TIndoorClimateMonitoring["state"] | undefined
  status?: TIndoorClimateMonitoring["status"]
  iconProps?: SvgIconProps
}) {
  if (state === "enabled") {
    if (status === "alerting") {
      return (
        <IndoorClimateFilledIcon
          fill={mColors.systemErrorLight}
          color={mColors.systemErrorDark}
          {...iconProps}
        />
      )
    }

    return (
      <IndoorClimateFilledIcon
        fill={mColors.systemInfoLight}
        color={mColors.systemInfoDark}
        {...iconProps}
      />
    )
  }

  return (
    <IndoorClimateFilledIcon
      fill={mColors.neutralDark}
      color={colorScale.koti[400]}
      {...iconProps}
    />
  )
}
