import { SvgIconProps } from "@material-ui/core"

import { TDisturbanceMonitoringActive } from "src/data/homes/types/homeTypes"
import NoiseMonitoringOffOutlineIcon from "src/ui/icons/noise-monitoring-off-outline.svg"
import NoiseMonitoringOffOutlineActiveIcon from "src/ui/icons/noise-monitoring-off-outline-active.svg"
import NoiseMonitoringOnOutlineIcon from "src/ui/icons/noise-monitoring-on-outline.svg"
import NoiseMonitoringOnOutlineActiveIcon from "src/ui/icons/noise-monitoring-on-outline-active.svg"

export function NoiseMonitoringToggleActiveIcon({
  toggleState,
  active,
  iconProps,
}: {
  toggleState: "on" | "off"
  active: TDisturbanceMonitoringActive
  iconProps?: SvgIconProps
}) {
  if (toggleState === "on") {
    if (!active) {
      return <NoiseMonitoringOnOutlineIcon {...iconProps} />
    }

    return <NoiseMonitoringOnOutlineActiveIcon {...iconProps} />
  }

  if (!active) {
    return <NoiseMonitoringOffOutlineActiveIcon {...iconProps} />
  }

  return <NoiseMonitoringOffOutlineIcon {...iconProps} />
}
