import { useState } from "react"
import styled, { css } from "styled-components"

import Dialog, { DialogProps } from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import { useMediaQuery } from "usehooks-ts"

import { breakpoint } from "src/constants/breakpoints"
import { shadowBottom, shadowTop } from "src/constants/shadows"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { IconButton } from "src/ui/Button/IconButton"
import { MButton, TMButtonProps } from "src/ui/Button/MButton"
import { TextButton } from "src/ui/Button/TextButton"
import CloseIcon from "src/ui/icons/close.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export interface IMDialog {
  onConfirm?: () => void
  onClose: () => void
  open: boolean
  autoFocus?: boolean
  title?: React.ReactNode
  description?: string | React.ReactNode
  cancelLabel?: string
  confirmLabel?: string
  hideClose?: boolean
  fullWidth?: boolean
  loading?: boolean
  responsive?: boolean
  showCancel?: boolean
  confirmButtonProps?: TMButtonProps
  formId?: string
  TitleComponent?: React.ComponentType
  children?: React.ReactNode
  error?: React.ReactNode
  infoAlert?: React.ReactNode
  centerActionButtons?: boolean
  showActionBar?: boolean
  unmountOnClose?: boolean
}

export type TMDialogProps = Omit<DialogProps, "title"> & IMDialog

export function MDialog({
  onConfirm,
  onClose,
  open,
  title,
  error,
  infoAlert,
  // Optional

  unmountOnClose,
  cancelLabel,
  hideClose = false,
  autoFocus = true,
  formId,
  confirmLabel,
  description,
  responsive = true,
  loading = false,
  showCancel = true,
  fullWidth = true,
  children = null,
  confirmButtonProps,
  TitleComponent = Title,
  centerActionButtons = false,
  showActionBar = true,
  ...props
}: TMDialogProps) {
  const mediumUp = useMediaQuery(`(${breakpoint.mediumUp})`)
  const { t } = useTranslate()

  const _confirmLabel = confirmLabel ?? t(langKeys.ok)
  const _cancelLabel = cancelLabel ?? t(langKeys.cancel)

  const [showTopBorder, setShowTopBorder] = useState(false)
  const [showBottomBorder, setShowBottomBorder] = useState(true)

  function handleScroll(e: React.UIEvent<HTMLElement>) {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget
    const scrollBottom = scrollHeight - scrollTop - clientHeight
    setShowBottomBorder(scrollBottom > 0)
    setShowTopBorder(scrollTop !== 0)
  }

  if (unmountOnClose && !open) {
    return null
  }

  return (
    <Dialog
      onClick={(e) => {
        e.stopPropagation()
      }}
      open={open}
      fullScreen={!mediumUp && responsive}
      onClose={() => !loading && onClose()}
      fullWidth={fullWidth}
      {...props}
    >
      <Top $showBorder={showTopBorder}>
        <TitleComponent>{title}</TitleComponent>

        {!hideClose && (
          <CloseButtonBox>
            <IconButton
              onClick={onClose}
              variant="subtle"
              size="small"
              Icon={CloseIcon}
            ></IconButton>
          </CloseButtonBox>
        )}

        {description && (
          <Description>
            <MText variant="body">{description}</MText>
          </Description>
        )}
      </Top>

      {children && (
        <StyledDialogContent onScroll={handleScroll}>
          <ContentBox>{children}</ContentBox>
        </StyledDialogContent>
      )}

      <StyledActions
        $hidden={!showActionBar}
        $showBorder={showBottomBorder}
        $centerContents={centerActionButtons}
      >
        {infoAlert && (
          <MBanner
            type="info"
            fullWidth
            style={{ flexBasis: "100%", marginBottom: spacing.S }}
          >
            {infoAlert}
          </MBanner>
        )}
        {error && (
          <MBanner
            fullWidth
            type="error"
            style={{ flexBasis: "100%", marginBottom: spacing.S }}
          >
            {error}
          </MBanner>
        )}
        <ActionItem>
          {showCancel && (
            <TextButton onClick={onClose}>{_cancelLabel}</TextButton>
          )}
        </ActionItem>
        <ActionItem>
          {formId ? (
            <MButton
              type="submit"
              loading={loading}
              disabled={loading}
              form={formId}
              {...confirmButtonProps}
            >
              {_confirmLabel}
            </MButton>
          ) : onConfirm ? (
            <MButton
              autoFocus={autoFocus}
              onClick={onConfirm}
              loading={loading}
              disabled={loading}
              {...confirmButtonProps}
            >
              {_confirmLabel}
            </MButton>
          ) : null}
        </ActionItem>
      </StyledActions>
    </Dialog>
  )
}

const gutters = css`
  padding: 0 ${spacing.XL};
`

const Top = styled.div<{ $showBorder: boolean }>`
  ${gutters};
  padding-bottom: ${spacing.L};
  padding-top: ${spacing.L};
  display: grid;
  grid-template-areas:
    "title close"
    "description description";
  grid-template-columns: 1fr auto;

  ${(props) => props.$showBorder && shadowBottom};
`

const Title = styled(MText).attrs(() => ({ variant: "heading2" }))``

const CloseButtonBox = styled.div`
  grid-area: close;
  place-self: top end;
  margin-right: -4px;
`

const Description = styled.div`
  grid-area: description;
  margin-top: ${spacing.XS};
`

const StyledDialogContent = styled(DialogContent)`
  ${gutters};
  padding-top: 0;

  &:first-child {
    padding-top: 0;
  }
`

const ContentBox = styled.div`
  margin-bottom: ${spacing.XL};
`

const StyledActions = styled.div<{
  $showBorder: boolean
  $centerContents: boolean
  $hidden?: boolean
}>`
  ${gutters};
  padding-top: ${spacing.M};
  flex-wrap: wrap;
  padding-bottom: ${spacing.M};
  display: flex;
  display: ${(props) => (props.$hidden ? "none" : "flex")};
  justify-content: ${(props) =>
    props.$centerContents ? "center" : "space-between"};

  ${(props) => props.$showBorder && shadowTop};
`

const ActionItem = styled.div`
  display: flex;
  align-items: center;
`
