import { createContext } from "react"

import { IAuthState } from "src/context/auth/useAuthState"
import { useFetchCurrentSubscription } from "src/data/billing/queries/billingQueries"
import { TCurrentSubscription } from "src/data/billing/types/billingTypes"
import { IOrganization } from "src/data/organizations/types/organizationTypes"
import { TUser } from "src/data/user/user"
import { TMaybeAxiosError } from "src/utils/tsUtil"

export const AppDataContext = createContext({
  get user(): TUser | undefined {
    return undefined
  },
  loading: false,
  get criticalError(): TMaybeAxiosError {
    return null
  },
  get authState(): IAuthState {
    return {
      authorization: null,
      setAuthorization(): void {},
    }
  },
  ready: false,
  refetchActiveOrg(): void {},
  clear(): void {},
  get activeOrg(): IOrganization | null | undefined {
    return null
  },
  setActiveOrgId(_id: string | null): void {},

  get numberOfHomes(): number | undefined {
    return undefined
  },

  get currentSubscription(): TCurrentSubscription | undefined | null {
    return undefined
  },

  get fetchCurrentSubscription():
    | ReturnType<typeof useFetchCurrentSubscription>
    | undefined {
    return undefined
  },
})
