import styled from "styled-components"

import { colorsLegacy, gray50 } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

export const StyledSelect = styled.div`
  padding: ${spacing.S} 0;
  width: 300px;
`

export const Option = styled.div<{ $selected?: boolean }>`
  padding: 4px ${spacing.S} 4px ${spacing.M};
  background-color: ${({ $selected }) =>
    $selected && colorsLegacy.backgroundGrayV2};

  &:hover {
    background-color: ${colorsLegacy.backgroundGrayV2};
  }
`

export const StyledSearch = styled.div`
  padding: 0 ${spacing.S} ${spacing.S} ${spacing.S};
`

export const EmptyBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${spacing.XL};
  color: ${colorsLegacy.minimumGray};
`

export const ItemsList = styled.div<{
  $showBorderTop: boolean
  $showBorderBottom: boolean
}>`
  max-height: 300px;
  overflow: auto;
  border-top: ${({ $showBorderTop }) =>
    $showBorderTop && `1px solid ${gray50}`};
  border-bottom: ${({ $showBorderBottom }) =>
    $showBorderBottom && `1px solid ${gray50}`};
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: no-wrap;
`

export const DropDownButtonLabel = styled.div<{ values: number }>`
  display: flex;
  gap: ${({ values }) => (values > 0 ? spacing.M : 0)};
  align-items: center;
`

export const ErrorBox = styled.div`
  padding: ${spacing.XS} 0 0 ${spacing.S};
  margin-top: ${spacing.S};
`

export const BoxBottom = styled.div`
  padding: ${spacing.XS} 0 0 ${spacing.S};
`
