import { SvgIconProps } from "@material-ui/core"

import { ISmokingDetection } from "src/data/homes/types/homeTypes"
import { colorScale, mColors } from "src/ui/colors"
import SmokingDetectionFilledIcon from "src/ui/icons/cigarette-detection-filled.svg"

export function SmokingDetectionIcon({
  state,
  status,
  iconProps,
}: {
  state: ISmokingDetection["state"] | undefined
  status?: ISmokingDetection["status"]
  iconProps?: SvgIconProps
}) {
  if (state === "enabled") {
    if (status === "alerting") {
      return (
        <SmokingDetectionFilledIcon
          fill={mColors.systemErrorLight}
          color={mColors.systemErrorDark}
          {...iconProps}
        />
      )
    }

    return (
      <SmokingDetectionFilledIcon
        fill={mColors.systemInfoLight}
        color={mColors.systemInfoDark}
        {...iconProps}
      />
    )
  }

  return (
    <SmokingDetectionFilledIcon
      fill={mColors.neutralDark}
      color={colorScale.koti[400]}
      {...iconProps}
    />
  )
}
