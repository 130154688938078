import { TCurrentSubscription } from "src/data/billing/types/billingTypes"
import { isString } from "src/utils/genericUtil"
import { Optional } from "src/utils/tsUtil"

export enum DiscountCodes {
  // only for users with no plan (including cancelled plans)
  STARTER75 = "STARTER75",
  UPGRADE2024 = "UPGRADE2024",
  // only for users on legacy plans
  SMOKE23 = "SMOKE23",
  SMOKE = "SMOKE",
  ANNUAL2024 = "ANNUAL2024",
}

export type TCurrentPlanForFilter = Pick<
  TCurrentSubscription,
  "subscription_status" | "plan" | "legacy_plan" | "custom_plan"
>

function filterLegacyOnlyCode(
  code: string,
  currentPlan: Optional<TCurrentPlanForFilter>
) {
  const codeMatch: string[] = [
    DiscountCodes.SMOKE,
    DiscountCodes.SMOKE23,
    DiscountCodes.ANNUAL2024,
  ]
  const codeCorrect = codeMatch.includes(code)
  const hasNoCustomPlan = !currentPlan?.custom_plan
  const hasLegacyPlan = currentPlan?.legacy_plan
  if ([codeCorrect, hasLegacyPlan, hasNoCustomPlan].every(Boolean)) {
    return code
  }
}

function filterStarterOnlyCode(
  code: string,
  currentPlan: Optional<TCurrentPlanForFilter>
) {
  const codeMatch: string[] = [
    DiscountCodes.STARTER75,
    DiscountCodes.UPGRADE2024,
  ]
  const codeCorrect = codeMatch.includes(code)
  const hasNoPlan = currentPlan == null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  const isStarter = currentPlan?.plan === ("starter" as any)
  const hasCancelledPlan = currentPlan?.subscription_status === "cancelled"
  if (codeCorrect && [hasNoPlan, isStarter, hasCancelledPlan].some(Boolean)) {
    return code
  }
}

export function filterDiscountCode({
  code,
  currentSubscription,
}: {
  code: string | DiscountCodes
  currentSubscription: Optional<TCurrentPlanForFilter>
}): Optional<string> {
  const codeUpperCased = code.toUpperCase()
  switch (codeUpperCased) {
    case DiscountCodes.SMOKE23:
    case DiscountCodes.SMOKE:
    case DiscountCodes.ANNUAL2024:
      return filterLegacyOnlyCode(codeUpperCased, currentSubscription)
    case DiscountCodes.STARTER75:
    case DiscountCodes.UPGRADE2024:
      return filterStarterOnlyCode(codeUpperCased, currentSubscription)
    default:
      return code
  }
}

export function getFilteredDiscountCodes({
  discountCodes,
  currentSubscription,
}: {
  discountCodes: string[] | undefined
  currentSubscription: Optional<TCurrentPlanForFilter>
}) {
  const filteredDiscountCodes: string[] | undefined = discountCodes
    ?.map((code) => filterDiscountCode({ currentSubscription, code }))
    .filter(isString)

  return filteredDiscountCodes
}
