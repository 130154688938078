import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function IndoorClimateAlerts({
  title,
  body,
  icon,
}: {
  title: string
  body: string
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
}) {
  const { t, langKeys } = useTranslate()

  const AlertIcon = icon

  return (
    <Box>
      <Title>
        <AlertIcon width={24} height={24} />
        <MText variant="subtitle">{title}</MText>

        <MBadge color="info">{t(langKeys.on)}</MBadge>
      </Title>

      <MText variant="bodyS" color="tertiary">
        {body}
      </MText>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  justify-content: center;
  gap: ${spacing.M};
`

const Title = styled.div`
  display: flex;
  gap: ${spacing.XS};
  justify-content: center;
  align-items: center;
`
