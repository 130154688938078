import { SvgIconProps } from "@material-ui/core"

import { TIndoorClimateMonitoring } from "src/data/homes/types/homeTypes"
import IndoorClimateOffIcon from "src/ui/icons/indoor-climate-outlined-off.svg"
import IndoorClimateOffActiveIcon from "src/ui/icons/indoor-climate-outlined-off-active.svg"
import IndoorClimateOnIcon from "src/ui/icons/indoor-climate-outlined-on.svg"
import IndoorClimateOnActiveIcon from "src/ui/icons/indoor-climate-outlined-on-active.svg"

export function IndoorClimateToggleActiveIcon({
  toggleState,
  state,
  iconProps,
}: {
  toggleState: "on" | "off"
  state: TIndoorClimateMonitoring["state"]
  iconProps?: SvgIconProps
}) {
  if (toggleState === "on") {
    if (state === "enabled") {
      return <IndoorClimateOnActiveIcon {...iconProps} />
    }

    return <IndoorClimateOnIcon {...iconProps} />
  }

  if (state === "enabled") {
    return <IndoorClimateOffIcon {...iconProps} />
  }

  return <IndoorClimateOffActiveIcon {...iconProps} />
}
