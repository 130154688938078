import styled from "styled-components"

import { SvgIconProps } from "@material-ui/core"

const INITIAL_OFFSET = 25

const circleConfig = {
  viewBox: "0 0 30 30",
  x: "15",
  y: "15",
  radius: "14",
}

export interface CircleProgressBarBaseProps {
  value?: number
  className?: string
  strokeColor?: string
  strokeWidth?: number
  innerText?: string
  legendText?: string
  trailStrokeWidth?: number
  trailStrokeColor?: string
  trailSpaced?: boolean
  icon?: React.ReactNode
  maxSize?: string
  textColor?: string
  progressBarProps?: SvgIconProps
}

export function CircleProgressBar({
  className = "",
  value = 0,
  strokeColor = "blueviolet",
  strokeWidth = 1,
  innerText = "Completed",
  legendText = "",
  trailStrokeWidth = 1,
  trailStrokeColor = "#d2d3d4",
  trailSpaced = false,
  icon,
  maxSize = "100vh",
  textColor = "black",
  progressBarProps = circleConfig,
}: CircleProgressBarBaseProps) {
  const progressBar = value
  return (
    <CircleProgressBarBase
      className={className}
      maxSize={maxSize}
      strokeColor={strokeColor}
      textColor={textColor}
    >
      <svg {...progressBarProps}>
        <circle
          className="donut-ring"
          cx={progressBarProps.x}
          cy={progressBarProps.x}
          r={progressBarProps.radius}
          fill="transparent"
          stroke={trailStrokeColor}
          strokeWidth={trailStrokeWidth}
          strokeDasharray={trailSpaced ? 1 : 0}
        />

        {icon}

        <circle
          className="donut-segment"
          cx={progressBarProps.x}
          cy={progressBarProps.x}
          r={progressBarProps.radius}
          fill="transparent"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeDasharray={`${progressBar} ${100 - progressBar}`}
          strokeDashoffset={INITIAL_OFFSET}
        />

        {!icon && (
          <g className="chart-text">
            <text x="50%" y="50%" className="chart-number">
              {Math.round(progressBar)}%
            </text>
            <text x="50%" y="50%" className="chart-label">
              {innerText}
            </text>
          </g>
        )}
      </svg>
      {legendText && (
        <figcaption className="figure-key">
          <ul
            className="figure-key-list"
            aria-hidden="true"
            role="presentation"
          >
            <li>
              <span className="shape-circle" />
              <span>{legendText}</span>
            </li>
          </ul>
        </figcaption>
      )}
    </CircleProgressBarBase>
  )
}

const CircleProgressBarBase = styled.figure<{
  maxSize: string
  textColor: string
  strokeColor: string
}>`
  max-width: ${(props) => props.maxSize};
  vertical-align: middle;
  width: 100px;
  margin: 2px;

  .chart-text {
    fill: ${(props) => props.textColor};
    transform: translateY(0.25em);
  }

  .chart-number {
    font-size: 0.6em;
    line-height: 1;
    text-anchor: middle;
    transform: translateY(-0.25em);
  }

  .chart-label {
    font-size: 0.2em;
    text-transform: uppercase;
    text-anchor: middle;
    transform: translateY(0.7em);
  }

  .figure-key [class*="shape-"] {
    margin-right: 8px;
  }

  .figure-key-list {
    list-style: none;
    display: flex;
    justify-content: space-between;
  }

  .figure-key-list li {
    margin: 5px auto;
  }

  .shape-circle {
    display: inline-block;
    vertical-align: middle;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: ${(props) => props.strokeColor};
    text-transform: capitalize;
  }
`
