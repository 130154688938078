import { SvgIconProps } from "@material-ui/core"

import { mColors } from "src/ui/colors"
import FireIcon from "src/ui/icons/fire-and-co.svg"
import COIcon from "src/ui/icons/fire-and-co-alarm.svg"

export type FireCOIconProps = {
  alerting: "fire" | "co" | null
  iconProps?: SvgIconProps
}

export function FireCOIcon({ alerting, iconProps }: FireCOIconProps) {
  if (alerting === "fire") {
    return (
      <FireIcon
        fill={mColors.systemErrorLight}
        color={mColors.systemErrorDark}
        {...iconProps}
      />
    )
  }
  if (alerting === "co") {
    return (
      <COIcon
        fill={mColors.systemErrorLight}
        color={mColors.systemErrorDark}
        {...iconProps}
      />
    )
  }
  return (
    <FireIcon
      fill={mColors.systemInfoLight}
      color={mColors.systemInfoDark}
      {...iconProps}
    />
  )
}
