import { SvgIconProps } from "@material-ui/core"

import { AlarmStatus } from "src/data/homes/types/alarmTypes"
import { colorScale, mColors } from "src/ui/colors"
import SecurityAlarmFilledIcon from "src/ui/icons/security-alarm-filled.svg"

export function SecurityAlarmIcon({
  alarmStatus,
  iconProps,
}: {
  alarmStatus: AlarmStatus | undefined
  iconProps?: SvgIconProps
}) {
  switch (alarmStatus) {
    case "critical_event":
    case "off_grace_period":
      return (
        <SecurityAlarmFilledIcon
          fill={mColors.systemErrorLight}
          color={mColors.systemErrorDark}
          {...iconProps}
        />
      )
    case "on":
      return (
        <SecurityAlarmFilledIcon
          fill={mColors.systemInfoLight}
          color={mColors.systemInfoDark}
          {...iconProps}
        />
      )
    case "off":
    case undefined:
      return (
        <SecurityAlarmFilledIcon
          fill={mColors.neutralDark}
          color={colorScale.koti[400]}
          {...iconProps}
        />
      )
    case "on_grace_period":
      return (
        <SecurityAlarmFilledIcon
          fill={mColors.systemInfoLight}
          color={mColors.systemInfoDark}
          {...iconProps}
        />
      )
    default:
      return <div></div>
  }
}
