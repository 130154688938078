import { Fragment, useMemo, useState } from "react"
import styled from "styled-components"

import { Checkbox } from "@material-ui/core"
import { useMediaQuery } from "usehooks-ts"

import { FireCOAlarmButton } from "src/components/Homes/HomeDetails/FireCOAlarm/FireCOAlarmButton"
import { IndoorClimateButton } from "src/components/Homes/HomeDetails/IndoorClimate/IndoorClimateButton"
import { NoiseMonitoringButton } from "src/components/Homes/HomeDetails/NoiseMonitoring/NoiseMonitoringButton"
import { OccupancyButton } from "src/components/Homes/HomeDetails/Occupancy/OccupancyButton"
import { SecurityAlarmButton } from "src/components/Homes/HomeDetails/SecurityAlarm/SecurityAlarmButton"
import { SmokingDetectionButton } from "src/components/Homes/HomeDetails/SmokingDetection/SmokingDetectionButton"
import { breakpoint } from "src/constants/breakpoints"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import { monitoringAvailable } from "src/data/homes/logic/homeUtil"
import { AlarmMode, AlarmStatus, IAlarm } from "src/data/homes/types/alarmTypes"
import {
  MonitoringType,
  TDisturbanceMonitoringActive,
  THome,
} from "src/data/homes/types/homeTypes"
import { TOrganizationRole } from "src/data/organizations/types/organizationMemberTypes"
import {
  getAccessLogic,
  toggleFireCoAlarmAllowed,
  toggleNoiseMonitoringAllowed,
  toggleSecurityAlarmAllowed,
  toggleSmokingDetectionAllowed,
} from "src/data/user/logic/accessLogic"
import { useDunning } from "src/data/user/logic/dunningLogic"
import { usePermissions } from "src/data/user/logic/usePermissions"
import { AccessLevelRank } from "src/data/user/types/accessTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { divider } from "src/ui/colors"
import { MDialog } from "src/ui/Dialog/MDialog"
import { GridTable } from "src/ui/GridTable/GridTable"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"

export function HomeTable({
  homes,
  devices = [],
  loading = false,
  checkedHomes,
  onHomeChecked,
  onAllHomesChecked,
  enableHomeChecking,
}: {
  homes: THome[]
  devices: TDevice[]
  loading: boolean
  checkedHomes?: THome[]
  onHomeChecked?: (home: THome, checked: boolean) => void
  onAllHomesChecked?: (checked: boolean) => void
  enableHomeChecking?: boolean
}) {
  const [showReactivateDialog, setShowReactivateDialog] = useState(false)

  const { t, langKeys } = useTranslate()
  const mobile = useMediaQuery(`(${breakpoint.normalDown})`)
  const { navigate } = useRouter()

  const { isCancelled } = useDunning()
  const permissions = usePermissions()
  const cigaretteSmokeAvailability = useFeatureAvailability({
    feature: Feature.CIGARETTE_SMOKE,
  })
  const { available: hasEurekaDevice } = useFeatureAvailability({
    feature: "eureka",
  })

  const indoorClimateAvailable = useFeatureAvailability({
    feature: "indoor_climate_monitoring",
  }).available

  const allSelected = !!homes.every((home) =>
    checkedHomes?.find((h) => h.home_id === home.home_id)
  )

  const transformedHomes = useMemo(() => {
    return transformHomes(homes, devices)
  }, [homes, devices])

  /* eslint-disable react/jsx-key */
  const headers = [
    enableHomeChecking ? (
      <div>
        <Checkbox
          checked={allSelected}
          onChange={(_, checked) => onAllHomesChecked?.(checked)}
        />
      </div>
    ) : (
      false
    ),
    <div>{t(langKeys.home)}</div>,
    <div>{t(langKeys.sound_noise_monitoring)}</div>,
    <div>{t(langKeys.crowd_detect_title)}</div>,
    cigaretteSmokeAvailability.available ? (
      <div>{t(langKeys.smoking_detection_title)}</div>
    ) : (
      false
    ),
    hasEurekaDevice ? <div>{t(langKeys.fire_and_co_alarm_title)}</div> : false,
    indoorClimateAvailable ? <div>{t(langKeys.indoor_climate)}</div> : false,
    <div>{t(langKeys.home_security_alarm_title)}</div>,
    <div>{t(langKeys.sensors_online)}</div>,
  ].filter((h): h is JSX.Element => h !== false)
  /* eslint-enable react/jsx-key */

  const rows = transformedHomes.map((home) => {
    const {
      homeAccessRank,
      homeId,
      name,
      homeDevices,
      alarmMode,
      silentAlarm,
      alarmInfo,
      violations,
      detailedAlarmStatus,
      disturbanceMonitoringActive,
      online,
      occupancy,
      homeUserRole,
      noiseMonitoringState,
    } = home

    const access = getAccessLogic({ role: homeUserRole })
    const canViewHome =
      homeAccessRank >= AccessLevelRank.MEMBER || access.hasMemberAccess
    const canToggleSecurity =
      toggleSecurityAlarmAllowed(homeAccessRank) || access.hasMemberAccess
    const canToggleNoiseMonitoring =
      toggleNoiseMonitoringAllowed(homeAccessRank) || access.hasAdminAccess
    const canToggleOccupancy =
      homeAccessRank >= AccessLevelRank.MEMBER || access.hasAdminAccess
    const canToggleSmokingDetection =
      toggleSmokingDetectionAllowed(homeAccessRank) || access.hasAdminAccess
    const canToggleFireCoAlarm =
      toggleFireCoAlarmAllowed(homeAccessRank) || access.hasAdminAccess

    const canToggleIndoorClimate = permissions.indoorClimateModify

    const isChecked = !!checkedHomes?.find((h) => h.home_id === homeId)

    if (mobile) {
      return (
        <MobileRow key={home.homeId}>
          <div>
            {enableHomeChecking && (
              <div>
                <Checkbox
                  checked={isChecked}
                  onChange={(_, checked) => onHomeChecked?.(home, checked)}
                />
              </div>
            )}
            {!canViewHome ? (
              <span>{name}</span>
            ) : (
              <InternalLink
                onClick={(e) => {
                  if (isCancelled) {
                    e.preventDefault()
                    setShowReactivateDialog(true)
                  }
                }}
                to={Routes.Home.location(homeId)}
              >
                {name}
              </InternalLink>
            )}
          </div>
          <div>
            {monitoringAvailable({
              type: MonitoringType.SOUND,
              home,
              devices: homeDevices,
            }) && (
              <NoiseMonitoringButton
                homeId={homeId}
                violations={violations}
                isActive={!!disturbanceMonitoringActive}
                hideStatus
                toggleAllowed={canToggleNoiseMonitoring}
                monitoringState={noiseMonitoringState}
                graphicButton
                row
              />
            )}

            {monitoringAvailable({
              type: MonitoringType.OCCUPANCY,
              home,
              devices: homeDevices,
            }) && (
              <OccupancyButton
                occupancy={occupancy}
                homeId={homeId}
                violations={violations}
                toggleAllowed={canToggleOccupancy}
                graphicalButton
                hideStatus
              />
            )}

            {cigaretteSmokeAvailability.available &&
              monitoringAvailable({
                type: MonitoringType.CIGARETTE,
                home,
                devices: homeDevices,
              }) && (
                <SmokingDetectionButton
                  homeId={homeId}
                  violations={violations}
                  hasAccess={canToggleSmokingDetection}
                  smokingDetection={home.smokingDetection}
                  hideStatus
                />
              )}

            {hasEurekaDevice &&
              monitoringAvailable({
                type: MonitoringType.FIRECO,
                home,
                devices: homeDevices,
              }) && (
                <FireCOAlarmButton
                  homeState={home}
                  configureAllowed={canToggleFireCoAlarm}
                  hideStatus
                  graphicalButton
                />
              )}

            {indoorClimateAvailable &&
              monitoringAvailable({
                type: MonitoringType.INDOOR_CLIMATE,
                home,
                devices: homeDevices,
              }) && (
                <IndoorClimateButton
                  homeId={home.homeId}
                  indoorClimateMonitoring={home.indoor_climate_monitoring}
                  toggleAllowed={canToggleIndoorClimate}
                  hideStatus
                />
              )}

            {monitoringAvailable({
              type: MonitoringType.MOTION,
              home,
              devices: homeDevices,
            }) && (
              <SecurityAlarmButton
                homeId={homeId}
                violations={violations}
                showAlarmArmDelayHint={false}
                alarmStatus={detailedAlarmStatus}
                gracePeriodExpiresAt={alarmInfo.grace_period_expires_at}
                gracePeriodSecs={alarmInfo.grace_period_secs}
                alarmMode={alarmMode}
                toggleAllowed={canToggleSecurity}
                silentAlarm={silentAlarm}
                graphicalButton={true}
                hideStatus
                row
              />
            )}
          </div>
        </MobileRow>
      )
    }

    return (
      <Fragment key={homeId}>
        {enableHomeChecking && (
          <div>
            <Checkbox
              checked={isChecked}
              onChange={(_, checked) => onHomeChecked?.(home, checked)}
            />
          </div>
        )}
        <div>
          {!canViewHome ? (
            name
          ) : (
            <InternalLink
              onClick={(e) => {
                if (isCancelled) {
                  e.preventDefault()
                  setShowReactivateDialog(true)
                }
              }}
              to={Routes.Home.location(homeId)}
            >
              {name}
            </InternalLink>
          )}
        </div>
        <StatusBox>
          {monitoringAvailable({
            type: MonitoringType.SOUND,
            home,
            devices: homeDevices,
          }) ? (
            <NoiseMonitoringButton
              homeId={homeId}
              monitoringState={noiseMonitoringState}
              violations={violations}
              isActive={!!disturbanceMonitoringActive}
              toggleAllowed={canToggleNoiseMonitoring}
              graphicButton
              row
              style={{ border: "none" }}
            />
          ) : (
            "-"
          )}
        </StatusBox>

        <StatusBox>
          {monitoringAvailable({
            type: MonitoringType.OCCUPANCY,
            home,
            devices: homeDevices,
          }) ? (
            <OccupancyButton
              occupancy={occupancy}
              homeId={homeId}
              violations={violations}
              toggleAllowed={canToggleOccupancy}
              graphicalButton
            />
          ) : (
            "-"
          )}
        </StatusBox>

        {cigaretteSmokeAvailability.available && (
          <StatusBox>
            {monitoringAvailable({
              type: MonitoringType.CIGARETTE,
              home,
              devices: homeDevices,
            }) ? (
              <SmokingDetectionButton
                homeId={homeId}
                violations={violations}
                hasAccess={canToggleSmokingDetection}
                smokingDetection={home.smokingDetection}
              />
            ) : (
              "-"
            )}
          </StatusBox>
        )}

        {hasEurekaDevice && (
          <StatusBox>
            {(home.fireCoAlarmAvailable && (
              <FireCOAlarmButton
                homeState={home}
                graphicalButton
                configureAllowed={canToggleFireCoAlarm}
              />
            )) ||
              "-"}
          </StatusBox>
        )}

        {indoorClimateAvailable && (
          <StatusBox>
            {monitoringAvailable({
              type: MonitoringType.INDOOR_CLIMATE,
              home,
              devices: homeDevices,
            }) ? (
              <IndoorClimateButton
                homeId={home.homeId}
                indoorClimateMonitoring={home.indoor_climate_monitoring}
                toggleAllowed={canToggleIndoorClimate}
              />
            ) : (
              "-"
            )}
          </StatusBox>
        )}

        <StatusBox>
          {monitoringAvailable({
            type: MonitoringType.MOTION,
            home,
            devices: homeDevices,
          }) ? (
            <SecurityAlarmButton
              homeId={homeId}
              violations={violations}
              showAlarmArmDelayHint={false}
              alarmStatus={detailedAlarmStatus}
              gracePeriodExpiresAt={alarmInfo.grace_period_expires_at}
              gracePeriodSecs={alarmInfo.grace_period_secs}
              alarmMode={alarmMode}
              silentAlarm={silentAlarm}
              toggleAllowed={canToggleSecurity}
              graphicalButton
              row
              style={{ border: "none" }}
            />
          ) : (
            "-"
          )}
        </StatusBox>

        <StatusBox>
          {online}/{homeDevices.length}
        </StatusBox>
      </Fragment>
    )
  })

  if (!homes || !cigaretteSmokeAvailability.ready) {
    return null
  }

  const templateColumns = enableHomeChecking
    ? `auto minmax(150px, 1fr) repeat(${headers.length - 2}, auto)`
    : `minmax(150px, 1fr) repeat(${headers.length - 1}, auto)`

  return (
    <>
      <TableBox>
        {mobile ? (
          loading ? (
            <MobileSkeleton />
          ) : (
            rows
          )
        ) : (
          <GridTable
            header={headers}
            rows={loading ? desktopSkeletonRows() : rows}
            templateColumns={templateColumns}
            useFallbackResponsiveMode
          />
        )}
      </TableBox>
      <MDialog
        open={showReactivateDialog}
        onClose={() => setShowReactivateDialog(false)}
        title={t(langKeys.rental_unit_reactivate_dialog_title)}
        description={t(langKeys.rental_unit_reactivate_dialog_description)}
        hideClose
        onConfirm={() => {
          navigate(Routes.ChangePlan.location())
        }}
        confirmLabel={t(langKeys.subscription_cancelled_banner_action)}
      />
    </>
  )
}

function MobileSkeleton() {
  const mobileSkeletonRow = () => (
    <MobileRow key={Math.random()}>
      <MSkeleton width={`${Math.floor(Math.random() * 40) + 60}%`} />
      <div>
        <MSkeleton variant="rect" width="25px" />
        <MSkeleton variant="rect" width="25px" />
        <MSkeleton variant="rect" width="25px" />
      </div>
    </MobileRow>
  )

  return <>{Array.from(Array(4), () => mobileSkeletonRow())}</>
}

function desktopSkeletonRows() {
  const desktopSkeletonRow = () => (
    <Fragment key={Math.random()}>
      {/* //home */}
      <div>
        <MSkeleton width={`${Math.floor(Math.random() * 40) + 60}%`} />
      </div>
      {/* //security button */}
      <div>
        <MSkeleton variant="rect" />
      </div>
      {/* //noise monitoring */}
      <div>
        <MSkeleton variant="rect" />
      </div>
      {/* //crowd detect */}
      <div>
        <MSkeleton variant="rect" />
      </div>
      {/* //sensors online */}
      <div>
        <MSkeleton variant="rect" />
      </div>
      {/* //sensors offline */}
      <div>
        <MSkeleton variant="rect" />
      </div>
    </Fragment>
  )

  return Array.from(Array(4), () => desktopSkeletonRow())
}

function transformHomes(homes: THome[] = [], devices: TDevice[] = []) {
  if (!homes) {
    return []
  }

  return homes.map((home) => {
    const homeDevices = devices.filter((d) => d.home === home.home_id)
    const offlineHomeDevices = homeDevices.filter((d) => d.offline)

    const offline = offlineHomeDevices.length || 0

    const homeAccessRank = AccessLevelRank.RESPONDER
    let alarmInfo: IAlarm
    let alarmMode: AlarmMode | undefined
    let silentAlarm: boolean
    let detailedAlarmStatus: AlarmStatus | undefined
    let disturbanceMonitoringActive: TDisturbanceMonitoringActive
    let homeUserRole: TOrganizationRole = "responder"

    /* eslint-disable prefer-const */
    alarmInfo = { ...home.alarm }
    alarmMode = home.alarm?.alarm_mode
    silentAlarm = !!home.alarm?.silent_alarm
    detailedAlarmStatus = home.alarm?.alarm_status
    disturbanceMonitoringActive =
      !!home.disturbance_monitoring?.disturbance_monitoring_active
    homeUserRole = home.user_role
    const noiseMonitoringState = home.disturbance_monitoring?.state_v2
    /* eslint-enable prefer-const */

    return {
      ...home,
      name: home.name,
      homeId: home.home_id,
      occupancy: home.occupancy,
      violations: home.violations,

      alarmMode,
      silentAlarm,
      alarmInfo,
      detailedAlarmStatus,
      disturbanceMonitoringActive,
      noiseMonitoringState,

      homeDevices,
      online: homeDevices.length - offline,
      offline,
      homeAccessRank,
      homeUserRole,
      smokingDetection: home.smoking_detection,
      fireCoAlarmAvailable: Boolean(
        home.fire_alarm?.available || home.carbon_monoxide_alarm?.available
      ),
    }
  })
}

const TableBox = styled.div`
  width: 100%;
  overflow-x: auto;
`

const MobileRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: ${spacing.L};
  column-gap: ${spacing.S};
  border-bottom: 1px solid ${divider};
  padding-bottom: ${spacing.M};
  margin-bottom: ${spacing.M};

  > :first-child {
    flex: 1 1 20ch;
    display: flex;
    align-items: center;
    gap: ${spacing.S};
  }

  > :last-child {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    gap: ${spacing.S};
    button {
      margin: 0;
      padding: 0;
    }
  }
`

const StatusBox = styled.div`
  align-items: center;
`
