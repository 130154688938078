import { useState } from "react"
import styled from "styled-components"

import { NoHomeGroups } from "src/components/EmptyState/NoHomeGroups"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import {
  useDeleteHomeGroup,
  useFetchHomeGroups,
} from "src/data/homeGroups/queries/homeGroupQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeGroups() {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()

  const { org } = useOrganization()
  const { limit, offset, setOffset } = useUrlPager()
  const [errorText, setErrorText] = useState<string>()

  const fetchHomeGroups = useFetchHomeGroups({
    orgId: org.id,
    filter: {
      limit,
      offset,
    },
  })
  const deleteHomeGroup = useDeleteHomeGroup()
  const homeGroups = fetchHomeGroups.data?.homegroups ?? []
  const [deleteHomeGroupId, setDeleteHomeGroupId] = useState<string>()
  const showEmptyState = homeGroups.length < 1 && !fetchHomeGroups.isLoading

  const canAdminHomeGroups = getAccessLogic({
    role: org.user_role,
  }).hasAdminAccess

  function handleCreateHomegroup() {
    navigate(Routes.CreateHomeGroup.location())
  }

  function handleDeleteHomeGroupClicked(hgId: string) {
    setDeleteHomeGroupId(hgId)
  }

  function handleDeleteHomeGroupConfirmed(hgId: string | undefined) {
    hgId &&
      deleteHomeGroup.mutate(
        { orgId: org.id, homeGroupId: hgId },
        {
          onSuccess: () => setDeleteHomeGroupId(undefined),
          onError: (error) => {
            error.response?.data?.error_key === "homegroup_not_empty" &&
              setErrorText(
                t(langKeys.organizations_team_delete_homegroup_error_text)
              )
          },
        }
      )
  }

  const rows: JSX.Element[] = homeGroups.map((hg) => {
    return (
      <>
        <div>
          <InternalLink to={Routes.HomeGroupDetails.path(hg.id)}>
            {hg.name}
          </InternalLink>
        </div>

        <div>
          {canAdminHomeGroups && (
            <MoreButton onDelete={() => handleDeleteHomeGroupClicked(hg.id)} />
          )}
        </div>
      </>
    )
  })

  if (fetchHomeGroups.isLoading) {
    return (
      <div>
        <MSkeleton height={30} />
        <MSkeleton height={30} />
        <MSkeleton height={30} />
      </div>
    )
  }

  if (showEmptyState) {
    return <NoHomeGroups />
  }

  return (
    <div>
      <Titlebar
        title={t(langKeys.organizations_home_group_plural)}
        description={
          <MText color="secondary" variant="body">
            {t(langKeys.organizations_home_groups_empty_state_body)}
          </MText>
        }
        titleVariant="heading2"
        actionBar={
          canAdminHomeGroups && (
            <MButton onClick={handleCreateHomegroup}>
              {t(langKeys.organizations_home_group_create_button_label)}
            </MButton>
          )
        }
        size="unset"
      />
      <GridTable
        header={[
          <div key={"home-group"}>{t(langKeys.organizations_home_group)}</div>,
          <div key={"misc"}></div>,
        ]}
        rows={rows}
        templateColumns="1fr min-content"
        useFallbackResponsiveMode
      />
      <Wrapper>
        <Pager
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={fetchHomeGroups.data?.paging.total_count}
        />
      </Wrapper>

      <ConfirmDialog
        title={t(langKeys.organizations_delete_team_confirm_title)}
        description={t(langKeys.organizations_home_groups_remove_confirm_body, {
          homegroup:
            homeGroups.find((hg) => hg.id === deleteHomeGroupId)?.name ?? "",
        })}
        loading={deleteHomeGroup.isLoading}
        open={!!deleteHomeGroupId}
        onClose={() => {
          setDeleteHomeGroupId(undefined)
          setErrorText(undefined)
        }}
        confirmButtonProps={{
          color: "destructive",
        }}
        confirmLabel={t(langKeys.delete)}
        TransitionProps={{ onExited: () => setDeleteHomeGroupId(undefined) }}
        onConfirm={() => handleDeleteHomeGroupConfirmed(deleteHomeGroupId)}
        error={errorText}
      />
    </div>
  )
}

const Wrapper = styled.div`
  margin-top: ${spacing.XL};
`
