import styled from "styled-components"

import { ParadiseOverviewExpandable } from "src/components/Paradise/ParadiseOverviewExpandable"
import { ParadiseSettingStatic } from "src/components/Paradise/ParadiseSettings/ParadiseSettingStatic"
import { Capitalize, CopyWrapper } from "src/components/Paradise/sharedStyles"
import { chargebeeInvoiceUrl, chargebeeOrderUrl } from "src/constants/hrefs"
import { TParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { IParadiseUser } from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"
import { Routes } from "src/router/routes"
import { CopyText } from "src/ui/CopyText/CopyText"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"

export function ParadiseOrderOverview({
  order,
  user,
}: {
  order: TParadiseOrder
  user?: IParadiseUser
}) {
  return (
    <Box>
      <ParadiseOverviewExpandable rawJson={order}>
        <ParadiseSettingStatic
          title="Order number"
          value={
            <CopyWrapper>
              <MText variant="bodyS">{order.order_number}</MText>
              <CopyText
                value={order.order_number}
                copiedText="Copied!"
                width={18}
              />
            </CopyWrapper>
          }
        />

        <ParadiseSettingStatic
          title="Customer"
          value={
            user ? (
              <div>
                <InternalLink to={Routes.ParadiseUser.location(user.id)}>
                  {user.email}
                </InternalLink>
                <CopyText value={user.email} width={16} copiedText="Copied!" />
              </div>
            ) : (
              <MSkeleton width={100} height={20} />
            )
          }
        />

        {order.invoice_id && (
          <ParadiseSettingStatic
            title="Invoice"
            value={
              <ExternalLink href={chargebeeInvoiceUrl(order.invoice_id)}>
                {order.invoice_id}
              </ExternalLink>
            }
          />
        )}

        <ParadiseSettingStatic
          title="Origin"
          value={
            <SourceBox>
              <Capitalize>{order.origin.name}</Capitalize>
              <OrderIdAtOrigin origin={order.origin} />
            </SourceBox>
          }
        />

        <ParadiseSettingStatic
          title="Created at"
          value={formatDate({
            date: order.created_at,
            timezone: "utc",
            clockType: "24",
          })}
        />

        <ParadiseSettingStatic
          title="Updated at"
          value={formatDate({
            date: order.updated_at,
            timezone: "utc",
            clockType: "24",
          })}
        />
      </ParadiseOverviewExpandable>
    </Box>
  )
}

function OrderIdAtOrigin({ origin }: { origin: TParadiseOrder["origin"] }) {
  if (!origin.order_id) {
    return null
  }

  if (origin.name === "chargebee") {
    return (
      <MText variant="bodyS" color="secondary">
        (Order source id:{" "}
        <ExternalLink href={chargebeeOrderUrl(origin.order_id)}>
          {origin.order_id}
        </ExternalLink>
        )
      </MText>
    )
  } else {
    return (
      <MText variant="bodyS" color="secondary">
        (Order source id: {origin.order_id})
      </MText>
    )
  }
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.L};
`

const SourceBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1ch;
`
